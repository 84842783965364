import React from "react";
import Picker from "@emoji-mart/react";
import { Data } from "emoji-mart";
import { marked } from "marked";
import {
  FaceSmileIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { TextBoldIcon, TextItalicIcon } from "../../../../components/icons";

export default function CreateTemplate() {
  const [text, setText] = React.useState("");
  const textAreaRef = React.useRef<any>(null);
  const [showEmojiPicker, setShowEmojiPicker] = React.useState(false);
  const [variable, setVariable] = React.useState<number>(1);

  const handleEmojiSelect = (emoji: any) => {
    setText(text + emoji.native);
    setShowEmojiPicker(!showEmojiPicker);
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const handleInputChange = (e: any) => {
    setText(e.target.value.replace(/\n/g, "  \n"));
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setText((prevText) => prevText + "<br />");
    }
  };

  const applyStyle = (style: any) => {
    const textarea = textAreaRef.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const selectedText = text.substring(start, end);

    const styledText = `<${style}>${selectedText}</${style}>`;
    const newText = text.substring(0, start) + styledText + text.substring(end);

    setText(newText);
  };

  const handleVariable = () => {
    setText((prevText) => prevText + `{{${variable}}}`);
  };

  return (
    <main className="max-w-7xl mx-auto grid grid-cols-12 gap-8 w-full h-full">
      <div className="col-span-9 flex flex-col gap-6 mb-16">
        <h1 className="font-semibold text-lg">Créer un nouveau template</h1>
        <hr />
        <section className="flex flex-col">
          <h1>Etape 1:</h1>
          <div className="flex items-center gap-8 mt-4">
            <div className="w-full">
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Catégories
              </label>
              <div className="mt-2">
                <select className="block w-full px-4 rounded-md border-0 py-1.5 h-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:outline-purple-600 focus:ring-purple-600 sm:text-sm sm:leading-6">
                  <option>Choisir la catégories</option>
                  <option>Utility</option>
                  <option>Authentication</option>
                  <option>Marketing</option>
                </select>
              </div>
            </div>
            <div className="w-full">
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Nom tu template
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  className="block w-full px-4 rounded-md border-0 h-10 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:outline-purple-600 focus:ring-purple-600 sm:text-sm sm:leading-6"
                  placeholder="Entrez le nom du template"
                />
              </div>
            </div>
            <div className="w-full">
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Langue
              </label>
              <div className="mt-2">
                <select className="block w-full px-4 rounded-md border-0 py-1.5 h-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:outline-purple-600 focus:ring-purple-600 sm:text-sm sm:leading-6">
                  <option>Choisir la langue</option>
                  <option>Français</option>
                  <option>Anglais</option>
                </select>
              </div>
            </div>
          </div>
        </section>
        <hr />
        <section className="flex flex-col">
          <h1>Etape 2:</h1>
          <div className="flex items-center justify-between gap-8 mt-4 w-full">
            <div className="flex items-center  w-full">
              <div className="w-full">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  En tête
                </label>
                <div className="mt-2 flex items-center gap-x-4">
                  <select className="block w-48 px-4 rounded-md border-0 py-1.5 h-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:outline-purple-600 focus:ring-purple-600 sm:text-sm sm:leading-6">
                    <option>Aucun</option>
                  </select>
                  <h1 className="text-sm">Aucun en-tête sélectionné</h1>
                </div>
              </div>
            </div>
            <div className="w-full">
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Bas de page
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  className="block px-4 w-full rounded-md border-0 h-10 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:outline-purple-600 focus:ring-purple-600 sm:text-sm sm:leading-6"
                  placeholder="Entrez le bas de page"
                />
              </div>
            </div>
          </div>
          <div className="mt-6">
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900 mb-2"
            >
              Body
            </label>
            <textarea
              ref={textAreaRef}
              value={text}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder="Saisissez votre texte ici..."
              className="font-medium w-full h-32 rounded-md border-0 py-1.5 px-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:outline-purple-600 focus:ring-purple-600 sm:text-sm sm:leading-6"
              rows={4}
              cols={50}
              style={{ whiteSpace: "pre-line" }}
            />
            <div className="flex items-center justify-end gap-4">
              <div className="">
                <FaceSmileIcon
                  className="h-5 w-5 cursor-pointer hover:text-purple-600"
                  onClick={() => toggleEmojiPicker()}
                />
                <div className="absolute  z-10">
                  {showEmojiPicker && (
                    <Picker data={Data} onEmojiSelect={handleEmojiSelect} />
                  )}
                </div>
              </div>
              <button
                className="bg-gray-50 p-2 rounded-md hover:bg-gray-100"
                onClick={() => applyStyle("strong")}
              >
                <TextBoldIcon className="h-4 w-4" />{" "}
              </button>
              <button
                className="bg-gray-50 p-2 rounded-md hover:bg-gray-100"
                onClick={() => applyStyle("em")}
              >
                <TextItalicIcon className="h-4 w-4" />{" "}
              </button>
              <button
                className="bg-gray-50 p-2 rounded-md flex font-normal shadow-md items-center gap-x-2 hover:bg-gray-100"
                onClick={() => {
                  handleVariable();
                  setVariable((prev) => prev + 1);
                }}
              >
                <PlusIcon className="h-4 w-4" />
                Ajouter une variable
              </button>
              {/* <button onClick={() => applyStyle("s")}>Barré</button> */}
            </div>
          </div>
        </section>
        <hr />
        <section className="flex flex-col">
          <div className="flex items-center justify-between gap-8 mt-4 w-full">
            <div className="flex items-center w-full">
              <div className="w-full">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Bouton
                </label>
                <div className="mt-2 flex items-center gap-x-4">
                  <select className="block w-48 px-4 rounded-md border-0 py-1.5 h-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:outline-purple-600 focus:ring-purple-600 sm:text-sm sm:leading-6">
                    <option>Appel à l'action</option>
                    <option>Visite le Site</option>
                  </select>
                  <button className="bg-gray-50 p-2 rounded-md flex text-xs h-10 font-normal shadow-md items-center gap-x-2 hover:bg-gray-100">
                    <PlusIcon className="h-4 w-4" />
                    Ajouter un autre bouton
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="relative border rounded-md p-6 mt-4 grid grid-cols-4 gap-x-4">
            <div className="w-full">
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900 mb-2"
              >
                Type de l'action
              </label>
              <div className="flex items-center w-full gap-x-4">
                <select className="block px-4 w-full rounded-md border-0 py-1.5 h-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:outline-purple-600 focus:ring-purple-600 sm:text-sm sm:leading-6">
                  <option>Appel à l'action</option>
                </select>
              </div>
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900 mb-2"
              >
                Texte du Bouton
              </label>
              <div className="flex items-center gap-x-4">
                <input
                  type="text"
                  className="block w-full px-4 rounded-md border-0 h-10 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:outline-purple-600 focus:ring-purple-600 sm:text-sm sm:leading-6"
                  placeholder="Entrez le nom du template"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Pays
              </label>
              <div className="mt-2 w-full">
                <select className="block px-4 w-full rounded-md border-0 py-1.5 h-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:outline-purple-600 focus:ring-purple-600 sm:text-sm sm:leading-6">
                  <option>Choisir le Pays</option>
                  <option>Cameroun</option>
                </select>
              </div>
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900 mb-2"
              >
                Numéro de télèphone
              </label>
              <div className="flex items-center gap-x-4">
                <input
                  type="text"
                  className="block  px-4 rounded-md border-0 h-10 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:outline-purple-600 focus:ring-purple-600 sm:text-sm sm:leading-6"
                  placeholder="Entrez le numéro de téléphone"
                />
              </div>
            </div>
            <div className="absolute right-2 bottom-7">
              <button className="bg-red-100 p-1 rounded-md">
                <TrashIcon
                  className="h-5 w-5 text-red-600"
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
          <div className="flex items-center justify-center mt-4">
            <button className="text-white bg-purple-600 hover:bg-purple-500 px-4 py-2 rounded-lg text-sm">
              Soumettre
            </button>
          </div>
        </section>
      </div>
      <div className="col-span-3">
        <div className="ml-4 flex flex-col gap-8">
          {/*  */}
          <div className="relative mx-auto border-purple-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[600px] w-[300px]">
            <div className="h-[32px] w-[3px] bg-purple-800 absolute -left-[17px] top-[72px] rounded-l-lg"></div>
            <div className="h-[46px] w-[3px] bg-purple-800 absolute -left-[17px] top-[124px] rounded-l-lg"></div>
            <div className="h-[46px] w-[3px] bg-purple-800 absolute -left-[17px] top-[178px] rounded-l-lg"></div>
            <div className="h-[64px] w-[3px] bg-purple-800 absolute -right-[17px] top-[142px] rounded-r-lg"></div>
            <div className="rounded-[2rem] overflow-hidden w-[272px] h-[572px] bg-gray-100 p-6">
              <h1 className="font-semibold text-sm mb-8">Aperçu du message:</h1>
              <div className="h-[450px] w-full bg-white my-8 rounded-lg p-4">
                <div
                  className="flex flex-wrap w-full break-all"
                  dangerouslySetInnerHTML={{ __html: marked(text!).toString() }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
