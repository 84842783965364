import React, { Fragment } from "react";
import { AddIcon, SearchIcon } from "../components/icons";
import { UserAvatar, UserMessage } from "../components";
import { userChats } from "../utils/mocks";
import classNames from "classnames";
import {
  ChatBubbleLeftIcon,
  ClockIcon,
  GlobeAltIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";

const secondTabs = [
  {
    name: "Tous",
    href: "#",
    current: true,
    state: 0,
    icon: GlobeAltIcon,
    notif: false,
  },
  {
    name: "Pending",
    href: "#",
    current: false,
    state: 1,
    icon: ClockIcon,
    notif: true,
  },
  {
    name: "Chats",
    href: "#",
    current: false,
    state: 2,
    icon: ChatBubbleLeftIcon,
    notif: true,
  },
  {
    name: "Groupes",
    href: "#",
    current: false,
    state: 3,
    icon: UserGroupIcon,
    notif: true,
  },
];

interface MessagesProps {
  openAside: boolean;
  chatDisplay: number;
  openChat: (index: number) => void;
}

export default function Messages({
  openAside,
  chatDisplay,
  openChat,
}: MessagesProps) {
  return (
    <aside
      className={classNames(
        { "left-40": openAside },
        { "left-20": !openAside },
        " transition-all duration-300 ease-in-out fixed inset-y-0 hidden w-80 overflow-y-auto border-r border-gray-200 xl:block mt-12"
      )}
    >
      <div className="flex justify-between border-b items-center">
        <nav className="-mb-px flex space-x-4 px-4 w-full" aria-label="Tabs">
          {secondTabs.map((tab) => (
            <div
              key={tab.name}
              className={classNames(
                0 === tab.state
                  ? "border-green-500 text-green-600"
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                "group relative flex flex-col items-center border-b-2 py-4 px-1 text-sm font-medium cursor-pointer"
              )}
              aria-current={tab.current ? "page" : undefined}
            >
              {tab.notif ? (
                <hr className="absolute right-0 p-1 bg-red-400 rounded-full" />
              ) : (
                <></>
              )}
              <tab.icon className="h-6 w-6" />
              <span>{tab.name}</span>
            </div>
          ))}
        </nav>
      </div>
      <div className="w-full px-3">
        <div className="h-16 bg-primary-white w-full">
          <div className="h-full flex flex-col justify-center items-center relative">
            <SearchIcon className=" h-4 w-4 absolute left-4 text-gray-500" />
            <input
              type="text"
              name=""
              id=""
              placeholder="Search messages..."
              className="bg-gray-100 text-gray-500 text-xs placeholder:text-gray-400 placeholder:text-sm placeholder:font-light font-light h-10 w-full rounded-xl outline-none pl-12 pr-16"
            />
          </div>
        </div>
        <div className="w-full divide-y">
          {userChats.map((item, key) => {
            return (
              <UserMessage
                className={classNames(
                  { "bg-gray-100": key === chatDisplay },
                  "hover:bg-gray-200 hover:rounded-lg cursor-pointer my-1"
                )}
                key={key}
                userChat={item}
                openChat={() => openChat(key)}
              />
            );
          })}
        </div>
      </div>
      <div className="flex border-t w-full justify-between absolute bottom-6 items-center px-4 sm:px-6 lg:px-4 py-4">
        <hr />

        <div className="flex items-center justify-center w-full gap-x-2">
          <div className="isolate flex overflow-hidden">
            <UserAvatar
              type="primary"
              name="Merlin"
              isOnline={true}
              size="lg"
            />
            <UserAvatar type="green" name="Paul" isOnline={true} size="lg" />
            <UserAvatar
              type="danger"
              name="Romario"
              isOnline={true}
              size="lg"
            />
            <UserAvatar type="warning" name="Sando" isOnline={true} size="lg" />
          </div>
        </div>
      </div>
    </aside>
  );
}
