import { ChatMessage, InputFormMessage, MenuList } from "../../../components";

import {
  ArrowsRightLeftIcon,
  BellSlashIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  PencilIcon,
  TrashIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/24/outline";
import { useLayoutContext } from "../../../core/context/layout-context";
import { Messages } from "../../../layouts";
import AsideInfo from "../../../layouts/aside-info";
import React from "react";

export default function Message() {
  const { asideOpen } = useLayoutContext();
  const [openChat, setOpenChat] = React.useState<number>(0);

  return (
    <main className="xl:px-80">
      <div className="h-screen">
        <div className="flex flex-col sticky top-10 w-full bg-white">
          <div className="px-4 sm:px-6 lg:px-4 py-10 lg:py-[14px]">
            <div className="flex justify-between items-center w-full">
              <div className="flex items-center gap-x-4 min-w-full">
                <img
                  className="rounded-md h-12 w-12"
                  src="https://randomuser.me/api/portraits/men/20.jpg"
                  alt=""
                />
                <div className="flex flex-col md:flex-row md:items-center gap-2 justify-between w-full">
                  <div className="flex flex-col w-full">
                    <h3 className="text-secondary-100 text-lg font-semibold">
                      Lavern Laboy
                    </h3>
                    <span className="flex items-center gap-x-1 text-xs text-gray-500 font-normal">
                      <hr className="p-1 bg-green-400 rounded-full" /> Online
                    </span>
                  </div>
                  <div className="flex items-center gap-x-2">
                    <div className="flex flex-row items-center gap-2 w-40">
                      <button
                        type="button"
                        className="bg-yellow-100 hover:bg-yellow-200 text-yellow-500 flex text-xs items-center px-2 py-1 rounded-md gap-x-2"
                      >
                        Non Résolu
                      </button>
                      <button
                        type="button"
                        className="bg-green-100 hover:bg-green-200 text-green-500 flex text-xs items-center px-2 py-1 rounded-md gap-x-2"
                      >
                        Assigner
                      </button>
                    </div>
                    <MenuList
                      items={[
                        {
                          icon: CheckCircleIcon,
                          name: "Résolu",
                        },
                        {
                          icon: PencilIcon,
                          name: "Epinglé",
                        },
                        {
                          icon: BellSlashIcon,
                          name: "Silencieux",
                        },
                        {
                          icon: TrashIcon,
                          name: "Supprimer",
                        },
                        {
                          icon: ArrowsRightLeftIcon,
                          name: "Assigner",
                        },
                      ]}
                      icon={
                        <EllipsisVerticalIcon className="h-6 w-6 text-gray-700" />
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>
        <div className="flex-grow h-full w-full flex flex-col mt-16">
          <ChatMessage displayChat={openChat} />
          <InputFormMessage />
        </div>
      </div>
      <AsideInfo />
      <Messages
        chatDisplay={openChat}
        openChat={(i) => setOpenChat(i)}
        openAside={asideOpen}
      />
    </main>
  );
}
