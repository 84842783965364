import { CheckIcon } from "@heroicons/react/24/solid";
import React from "react";
import StepOne from "./step-one";
import StepTwo from "./step-two";
import StepTree from "./step-tree";
import StepFour from "./step-four";
import StepFive from "./step-five";
import StepSix from "./step-six";
import StepTemplate from "./step-template";
const steps = [
  { id: "01", name: "Sélectionnez le numéro", status: 1 },
  { id: "02", name: "Sélectionnez le mode", status: 2 },
  { id: "02", name: "Sélectionnez le template", status: 3 },
  { id: "03", name: "Nommez votre campagne", status: 4 },
  { id: "04", name: "Définir le public", status: 5 },
  { id: "05", name: "Configurer la campagne", status: 6 },
  { id: "05", name: "Confirmation", status: 7 },
];

export default function OnboardingCampagne() {
  const [status, setStatus] = React.useState<number>(1);

  const onboarding = () => {
    switch (status) {
      case 2:
        return <StepTwo handleNext={() => setStatus(3)} />;
      case 3:
        return <StepTemplate handleNext={() => setStatus(4)} />;
      case 4:
        return <StepTree handleNext={() => setStatus(5)} />;
      case 5:
        return <StepFour handleNext={() => setStatus(6)} />;
      case 6:
        return <StepFive handleNext={() => setStatus(7)} />;
      case 7:
        return <StepSix handleNext={() => setStatus(8)} />;
      default:
        return <StepOne handleNext={() => setStatus(2)} />;
    }
  };

  const handlePrev = () => {
    setStatus((old) => old - 1);
  };

  return (
    <div>
      <nav aria-label="Progress" className="pb-6">
        <ol role="list" className="space-y-4 md:flex md:space-x-8 md:space-y-0">
          {steps.map((step) => (
            <li key={step.name} className="md:flex-1">
              {step.status === status ? (
                <span className="group flex flex-col border-l-4 border-purple-600 py-2 pl-4 hover:border-purple-800 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4">
                  <span className="text-xs font-light text-purple-500">
                    {step.name}
                  </span>
                </span>
              ) : step.status < status ? (
                <span
                  onClick={() => handlePrev()}
                  className="group flex cursor-pointer flex-col border-l-4 border-purple-600/50 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                >
                  <span className="text-xs font-light text-purple-500/50">
                    {step.name}
                  </span>
                </span>
              ) : (
                <span className="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4">
                  <span className="text-xs font-light text-gray-500">
                    {step.name}
                  </span>
                </span>
              )}
            </li>
          ))}
        </ol>
      </nav>
      {onboarding()}
    </div>
  );
}
