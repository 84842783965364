import classNames from "classnames";
import {
  EyeIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { CAMPAGNE_NEW_TEMPLATE } from "../../../../core/constants/layout";
import { useNavigate } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";

const templates = [
  {
    name: "message_diffusion",
    createdAt: "05-May-2024 02:04",
    id: "443875588990650",
    category: "UTILITY",
    status: "approved",
    language: "fr",
  },
  {
    name: "message_diffusion",
    createdAt: "05-May-2024 02:04",
    id: "443875588990650",
    category: "MARKETING",
    status: "approved",
    language: "en",
  },
  {
    name: "message_diffusion",
    createdAt: "05-May-2024 02:04",
    id: "443875588990650",
    category: "AUTHENTICATION",
    status: "approved",
    language: "fr",
  },
  {
    name: "message_diffusion",
    createdAt: "05-May-2024 02:04",
    id: "443875588990650",
    category: "UTILITY",
    status: "approved",
    language: "fr",
  },
];

interface StepTemplateProps {
  handleNext: () => void;
}
export default function StepTemplate({ handleNext }: StepTemplateProps) {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`${CAMPAGNE_NEW_TEMPLATE}`);
    window.location.reload();
  };
  return (
    <div className=" max-w-7xl mx-auto ">
      <div className="flex flex-col divide-y ">
        <div className=" px-4 py-4 gap-x-4  ">
          <div className="">
            <div className="mt-8 ">
              <div className="flex flex-col divide-y border ">
                <div className="flex items-center justify-between px-5 py-2">
                  <div className="flex gap-4">
                    <input
                      type="text"
                      className="border rounded-md h-10 px-2 w-96 font-light text-gray-600 placeholder:text-sm placeholder:text-gray-400 placeholder:font-light focus:outline-purple-600"
                      placeholder="Rechercher un template"
                    />
                  </div>
                  <div className="flex items-center gap-x-2">
                    <button
                      onClick={() => handleNavigate()}
                      className="text-white bg-purple-600 hover:bg-purple-500 px-4 py-2 rounded-lg text-sm"
                    >
                      Créer un template
                    </button>
                  </div>
                </div>
                <div className=" ">
                  <>
                    <div className="px-4 sm:px-6 lg:px-8 h-full">
                      <div className="my-4 flow-root">
                        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8 ">
                          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <table className="min-w-full divide-y divide-gray-300 h-full">
                              <thead>
                                <tr>
                                  <th
                                    scope="col"
                                    className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
                                  >
                                    NOM DU TEMPLATE
                                  </th>

                                  <th
                                    scope="col"
                                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                                  >
                                    DATE DE CREATION
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                                  >
                                    ID
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                                  >
                                    CATE!GORIE
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                                  >
                                    STATUS
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                                  >
                                    LANGUE
                                  </th>
                                  <th
                                    scope="col"
                                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                                  ></th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-200 bg-white">
                                {templates.map((person, i) => (
                                  <tr
                                    key={i}
                                    onClick={handleNext}
                                    className="cursor-pointer hover:bg-gray-100"
                                  >
                                    <td className="whitespace-nowrap flex  gap-y-1 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                      <span className="">{person.name}</span>
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {person.createdAt}
                                    </td>

                                    <td className="whitespace-nowrap  px-3 py-4 text-sm text-gray-500">
                                      {person.id} <br />
                                    </td>
                                    <td className="whitespace-nowrap flex px-3 py-4 text-sm text-gray-500">
                                      <span>{person.category}</span>
                                    </td>
                                    <td className="whitespace-nowrap px-3 font-light py-4 text-sm text-gray-500">
                                      <span
                                        className={classNames(
                                          {
                                            "bg-yellow-100 text-yellow-600":
                                              person.status === "pending",
                                          },
                                          {
                                            "bg-green-100 text-green-600":
                                              person.status === "approved",
                                          },
                                          {
                                            "bg-red-100 text-red-600":
                                              person.status === "rejected",
                                          },
                                          " px-4 py-1 font-semibold rounded-md text-xs"
                                        )}
                                      >
                                        {person.status}
                                      </span>
                                    </td>
                                    <td className="whitespace-nowrap px-3 font-light py-4 text-sm text-gray-500">
                                      <span>{person.language}</span>
                                    </td>
                                    <td className="whitespace-nowrap px-3 font-light py-4 text-sm text-gray-500">
                                      <span className="text-xs text-white bg-purple-600 px-2 py-1 rounded-lg">
                                        Sélectionner
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
