import classNames from "classnames";
import React from "react";
import AddContactDialog from "./contact/add-contact-dialog";
import {
  IdentificationIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";

const secondTabs = [
  {
    name: "Tous",
    href: "#",
    current: true,
    state: 0,
  },
  {
    name: "Bloqué",
    href: "#",
    current: false,
    state: 1,
  },
];

const people = [
  {
    name: "Lindsay ",
    title: "Walton",
    numero: "+237655413390",
    email: "lindsay.walton@example.com",
  },
  {
    name: "Lindsay Walton",
    title: "Walton",
    numero: "+237655413390",
    email: "courtney.henry@example.com",
  },
  {
    name: "Lindsay Walton",
    title: "Walton",
    numero: "+237655413390",
    email: "tom.cook@example.com",
  },
  {
    name: "Lindsay Walton",
    title: "Walton",
    numero: "+237655413390",
    email: "whitney.francis@example.com",
  },
  {
    name: "Lindsay Walton",
    title: "Walton",
    numero: "+237655413390",
    email: "leonard.krasner@example.com",
  },
];

export default function Contact() {
  const [statusTab, setStatusTab] = React.useState<number>(0);
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <div className=" max-w-6xl mx-auto">
        <div className="flex flex-col divide-y">
          <div className=" px-4 py-4 gap-x-4 ">
            <div>
              <div className="flex items-center gap-2">
                <IdentificationIcon className="h-6 w-6" />
                <h1>Contacts</h1>
              </div>
              <div className="mt-8">
                <div className="flex flex-col divide-y border">
                  <div className="flex items-center justify-between px-5 py-2">
                    <input
                      type="text"
                      className="border rounded-md h-10 px-2 w-96 font-light text-gray-600 placeholder:text-sm placeholder:text-gray-400 placeholder:font-light focus:outline-purple-600"
                      placeholder="Rechercher des contacts"
                    />
                    <div className="flex items-center gap-x-2">
                      <button className="border hover:bg-white/50 px-4 py-2 rounded-sm text-sm">
                        Filtre
                      </button>
                      <button className="border hover:bg-white/50 px-4 py-2 rounded-sm text-sm">
                        Importer contact
                      </button>
                      <button
                        onClick={() => setOpen(true)}
                        className="text-white bg-purple-600 hover:bg-purple-500 px-4 py-2 rounded-lg text-sm"
                      >
                        Ajouter le contact
                      </button>
                    </div>
                  </div>
                  <div>
                    <div className="flex justify-start">
                      <nav
                        className="-mb-px flex space-x-8 px-5"
                        aria-label="Tabs"
                      >
                        {secondTabs.map((tab) => (
                          <div
                            key={tab.name}
                            onClick={() => setStatusTab(tab.state)}
                            className={classNames(
                              statusTab === tab.state
                                ? "border-purple-500 text-purple-600"
                                : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                              "group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium cursor-pointer"
                            )}
                            aria-current={tab.current ? "page" : undefined}
                          >
                            <span>{tab.name}</span>
                          </div>
                        ))}
                      </nav>
                    </div>
                    {statusTab === 0 ? (
                      <>
                        <div className="px-4 sm:px-6 lg:px-8">
                          <div className="mt-8 flow-root">
                            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <table className="min-w-full divide-y divide-gray-300">
                                  <thead>
                                    <tr>
                                      <th
                                        scope="col"
                                        className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
                                      >
                                        NOM
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                                      >
                                        PRENOM
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                                      >
                                        NUMÉRO
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                                      >
                                        EMAIL
                                      </th>

                                      <th
                                        scope="col"
                                        className="relative py-3 pl-3 pr-4 sm:pr-0"
                                      >
                                        <span className="sr-only">Edit</span>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="divide-y divide-gray-200 bg-white">
                                    {people.map((person) => (
                                      <tr key={person.email}>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                          {person.name}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                          {person.title}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                          {person.numero}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                          {person.email}
                                        </td>

                                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                          <button className="bg-yellow-100 p-1 rounded-md">
                                            <PencilSquareIcon
                                              className="h-5 w-5 text-yellow-600"
                                              aria-hidden="true"
                                            />
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="flex flex-col justify-center items-center py-8 gap-y-6">
                        <img
                          src="/assets/images/campagnes/not_found.png"
                          alt=""
                          className="h-40 w-40"
                        />
                        <h1 className="text-2xl font-semibold text-purple-600">
                          Aucun contact trouvé
                        </h1>
                        <h5 className="text-sm font-normal text-gray-500">
                          Vous pouvez essayer avec une autre recherche ou
                          modifier les filtres de recherche
                        </h5>
                        <div className="flex items-center gap-x-2">
                          <button className="text-white bg-purple-600 hover:bg-purple-500 px-4 py-2 rounded-lg text-sm">
                            Réinitialiser la recherche
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddContactDialog open={open} setOpen={() => setOpen(!open)} />
    </>
  );
}
