import { BrowserRouter, Route, Routes } from "react-router-dom";
import DashboardPage from "./pages/dashboard/dashboard-page";
import ConversationsPage from "./pages/conversations/conversations-page";
import { Layout } from "./layouts";
import { LayoutProvider } from "./core/context/layout-context";
import { ThemeProvider } from "./core/context/theme-context";
import CampagnesPage from "./pages/campagnes/campagne-page";
import CrmClientPage from "./pages/crm-client/crm-client-page";
import FinancePage from "./pages/finance/finance-page";
import BigDataPage from "./pages/big-data/bigdata-page";
import LoginPage from "./pages/authentication/login-page";

function App() {
  return (
    <>
      <BrowserRouter>
        <ThemeProvider>
          <LayoutProvider>
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route
                path="/dashboard"
                element={
                  <Layout>
                    <DashboardPage />
                  </Layout>
                }
              />
              <Route
                path="/conversations"
                element={
                  <Layout>
                    <ConversationsPage />
                  </Layout>
                }
              />
              <Route
                path="/campagnes"
                element={
                  <Layout>
                    <CampagnesPage />
                  </Layout>
                }
              />
              <Route
                path="/crm-client"
                element={
                  <Layout>
                    <CrmClientPage />
                  </Layout>
                }
              />
              <Route
                path="/finance"
                element={
                  <Layout>
                    <FinancePage />
                  </Layout>
                }
              />
              <Route
                path="/bigdata"
                element={
                  <Layout>
                    <BigDataPage />
                  </Layout>
                }
              />
            </Routes>
          </LayoutProvider>
        </ThemeProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
