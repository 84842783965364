import classNames from "classnames";
import { UserChat } from "../core/models";

import UserAvatar from "./user-avatar";
import {
  ArrowsRightLeftIcon,
  BellSlashIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import MenuList from "./menu-list";

interface UserMessageProps {
  userChat: UserChat;
  className?: string;
  openChat: () => void;
}

export default function UserMessage({
  userChat,
  className,
  openChat,
}: UserMessageProps) {
  return (
    <div
      onClick={openChat}
      className={classNames(
        className,

        "py-2 pl-3 flex justify-between items-center w-full"
      )}
    >
      <div className="flex items-center gap-x-4 min-w-full">
        <img className="rounded-md h-12 w-12" src={userChat.urlImage} alt="" />
        <div className="flex flex-col gap-y-1 w-full">
          <div className="relative flex flex-row items-center justify-between">
            <h3 className="text-secondary-100 text-sm font-semibold">
              {userChat.userName}
            </h3>
            <div className="absolute right-1 -top-2 flex flex-col justify-end items-end">
              <div className="flex items-center gap-2">
                <h5 className=" text-xs text-gray-400 font-semibold rounded-xl">
                  {userChat.time}
                </h5>
                <MenuList
                  items={[
                    {
                      icon: CheckCircleIcon,
                      name: "Résolu",
                    },
                    {
                      icon: PencilIcon,
                      name: "Epinglé",
                    },
                    {
                      icon: BellSlashIcon,
                      name: "Silencieux",
                    },
                    {
                      icon: TrashIcon,
                      name: "Supprimer",
                    },
                    {
                      icon: ArrowsRightLeftIcon,
                      name: "Assigner",
                    },
                  ]}
                  icon={<ChevronDownIcon className="h-4 w-4 text-gray-400" />}
                />
              </div>
              {userChat.withAgent ? (
                <UserAvatar
                  type="primary"
                  name="Sando"
                  isOnline={false}
                  size="md"
                />
              ) : (
                <></>
              )}
            </div>
          </div>
          <p className="text-[10px] text-gray-500 font-normal">
            {userChat.description}
          </p>
        </div>
      </div>
    </div>
  );
}
