import { UserChat } from "../core/models";

export const userChats: UserChat[] = [
  {
    userName: "Elmer Laverty",
    urlImage: "https://randomuser.me/api/portraits/men/21.jpg",
    time: "12m",
    description: "Le lorem ipsum (également...",
    withAgent: true,
  },
  {
    userName: "Adam Joseph",
    urlImage: "https://randomuser.me/api/portraits/men/22.jpg",
    time: "20m",
    description: "Le lorem ipsum ...",
    withAgent: false,
  },
  {
    userName: "Julia Kim",
    urlImage: "https://randomuser.me/api/portraits/women/22.jpg",
    time: "1h",
    description: "Le lorem ipsum (également...",
    withAgent: false,
  },
  {
    userName: "Lavern Laboy",
    urlImage: "https://randomuser.me/api/portraits/men/20.jpg",
    time: "2h",
    description: "Le lorem ipsum (également...",
    withAgent: false,
  },
  {
    userName: "Geoffrey Mott",
    urlImage: "https://randomuser.me/api/portraits/men/29.jpg",
    time: "1h",
    description: "Le lorem ipsum (également...",
    withAgent: true,
  },
  {
    userName: "Titus Kitamura",
    urlImage: "https://randomuser.me/api/portraits/women/12.jpg",
    time: "2d",
    description: "Le lorem ipsum (également...",
    withAgent: true,
  },
];

export const conversations = [
  {
    id: 1,
    sender: "Lavern Laboy",
    recipient: "John Doe",
    message: "Salut John, comment ça va ?",
    time: "10:00 AM",
  },
  {
    id: 2,
    sender: "John Doe",
    recipient: "Lavern Laboy",
    message: "Salut Lavern, je vais bien merci ! Et toi ?",
    time: "10:05 AM",
  },
];
