import React, { Fragment, useEffect, useRef, useState } from "react";
import Editor from "./editor";
import { AddIcon, SendIcon } from "./icons";
import Quill from "quill";
import {
  DocumentIcon,
  FolderIcon,
  PhotoIcon,
  PlusCircleIcon,
  PlusIcon,
  ShareIcon,
} from "@heroicons/react/24/outline";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import ChatMenu from "./menu-list";
import ActionsMenu from "./actions-menu";

const Delta = Quill.import("delta");

export default function InputFormMessage() {
  const [readOnly, setReadOnly] = React.useState(false);
  const [range, setRange] = React.useState();
  const [lastChange, setLastChange] = React.useState();
  const quillRef = React.useRef();
  return (
    <div className="h-15 p-3 bg-white bottom-6 w-full sticky">
      <div className="flex items-end justify-end relative">
        <div className="p-2">
          <ActionsMenu
            icon={
              <AddIcon className="cursor-pointer h-6 w-6 text-black hover:text-green-600" />
            }
          />
          {/* <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="inline-flex w-full justify-center rounded-md py-2 text-sm font-medium text-white group focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
                <AddIcon className="cursor-pointer h-6 w-6 text-black group-hover:text-green-600" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute -top-48 right-2 mt-2  origin-top-right divide-y divide-gray-100 bg-transparent ring-black/5 focus:outline-none">
                <div className="px-1 py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? "bg-green-500" : "text-gray-900"
                        } group flex items-center justify-center border shadow-lg rounded-full px-2 py-2 text-sm my-2`}
                      >
                        <ShareIcon
                          className="h-8 w-8 group-hover:text-white"
                          aria-hidden="true"
                        />
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? "bg-green-500" : "text-gray-900"
                        } group flex items-center justify-center border shadow-lg rounded-full px-2 py-2 text-sm my-2`}
                      >
                        <FolderIcon
                          className="h-8 w-8 group-hover:text-white"
                          aria-hidden="true"
                        />
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? "bg-green-500" : "text-gray-900"
                        } group flex items-center justify-center border shadow-lg rounded-full px-2 py-2 text-sm my-2`}
                      >
                        <PhotoIcon
                          className="h-8 w-8 group-hover:text-white"
                          aria-hidden="true"
                        />
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu> */}
        </div>
        <div className="search-chat flex flex-grow ">
          <Editor />
          <div className="flex flex-col justify-end items-center  text-gray-400 p-2">
            <SendIcon className="text-green-600" />
          </div>
        </div>
      </div>
    </div>
  );
}
